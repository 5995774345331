import './AboutGame.scss';
import chatGpt from '../../../images/home/ChatGPT.png';
import featuresContent from '../../../data/GameFeatures.json';
import { useInView } from 'react-intersection-observer'

import first from '../../../images/home/game_features/first.jpg'
import second from '../../../images/home/game_features/second.jpg'
import third from '../../../images/home/game_features/third.jpg'

const getImage = (feture: string) => {
  switch(feture) {
    case 'first':
      return first;

    case 'second':
      return second;

    case 'third':
      return third;

      default:
        throw new Error('something went wrong!')
  }
}

const AboutGame = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });

  return (
    <section className="about_game" ref={ref}>
      <section className="container about_game__container">
        <div className="main_info">
          <div className="main_info__top">
            <h1>
              <span className="bold">Ghoons</span> NFT
            </h1>
            <p className="bold main_info__partners_title">
               BUILDING A MULTICHAIN PROJECT TO CONNECT SEI AND SOLANA CHAINS
            </p>
            {/* <ul className="main_info__partners">
              <li className="main_info__partners_item">
                <img src={chatGpt} alt="chat gpt" className="chat-gpt"/>
                <h2 className="bold">ChatGPT</h2>
              </li>
              <li className="main_info__partners_item">
                <h2 className="bold">BLOOM</h2>
              </li>
              <li className="main_info__partners_item">
                <h2 className="bold">DALL-E 2</h2>
              </li>
            </ul> */}
          </div>
          <div className="main_info__bottom">
            <p className="main_info__text">
              IN THE CURRENT MARKET CONDITION WE AS TEAM ARE TRYING TO CREATE SOMETHING OF VALUE WITH THE BEST POSSIBLE DEV TEAM
            </p>
            <p className="main_info__text">
              A LOT IS YET TO BE ANNOUNCED FOR OUR UPCOMING COLLABS AND UTILITY 
            </p>
          </div>
            {/* <p className="main_info__post_script">
              All content generated by artificial intelligence is manually moderated by the game's authors*
            </p> */}
        </div>

        <div className="info_game">
          <h2 className="bold">
            DISCOVER , STAY-TUNED AND FOLLOW THE PROJECT 
          </h2>
          <p>
          Ghoons NFT is an innovative digital art project revolutionizing the world of non-fungible tokens (NFTs). With its unique blend of vibrant colors, intricate designs, and captivating storytelling, Ghoons NFT stands out as a beacon of creativity in the burgeoning NFT landscape. Each Ghoons artwork is meticulously crafted by talented artists, offering collectors a one-of-a-kind digital masterpiece to treasure
          </p>
        </div>

        <ul className="game_features">
          {inView && featuresContent.map((currentFeature, i) => {
            const {photo, text} = currentFeature;

            return (
              <li key={i} className="game_features__item">
                <img
                  src={getImage(photo)}
                  alt={`${i} feature`}
                  className="game_features__image"
                />
                <p>
                  {text}
                </p>
              </li>
            );
          })}
        </ul>

      </section>
    </section>
  );
};

export default AboutGame;